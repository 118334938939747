<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="addFeedbackDetailsReport" class="w-100">
      <div class="row">
        <div class="col-12 text-right">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" v-if="toEdit" @click="toEdit = false">Cancel</button>
          <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit Feedback</button>
          <button class="e-btn e-btn-blue ml-2" type="submit" v-if="toEdit" >Save</button>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td style="width: 25%">Feedback Details</td>
          <td v-if="toEdit">
            <textarea rows="10" class="form-control form-control-sm mb-1 pre-wrap-txt"
                      v-model="feedback.feedback_detail"></textarea>
          </td>
          <td class="pre-wrap-txt" v-else>
            {{accidentIncidentReport.feedback_detail}}
          </td>
        </tr>
        <tr>
          <td >Feedback Subject</td>
          <td v-if="toEdit">
            <textarea rows="10" class="form-control form-control-sm mb-1 pre-wrap-txt"
                      v-model="feedback.feedback_subject"></textarea>
          </td>
          <td class="pre-wrap-txt" v-else>
            {{accidentIncidentReport.feedback_subject}}
          </td>
        </tr>
      </table>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const { ReportService } = require('../../../services/ReportService')
const { AlertService } = require('../../../services/AlertService')

export default {
  name: 'Feedback',
  data () {
    return {
      feedback: {
        feedback_detail: '',
        feedback_subject: ''
      },
      reportId: this.$route.params.id,
      toEdit: false
    }
  },
  methods: {
    ...mapActions(['getAccidentIncidentReport']),
    async addFeedbackDetailsReport () {
      if (await AlertService.confirmCreateAlert()) {
        this.feedback.report_id = this.reportId
        await ReportService.addFeedbackDetailsReport(this.feedback)
        AlertService.successAlert('Updating Feedback Action Details', 'UPDATE')
        await this.getAccidentIncidentReport(this.reportId)
        this.toEdit = false
      }
    },
    async editValues () {
      this.toEdit = true
      if (this.toEdit) {
        this.feedback = Object.assign({}, this.accidentIncidentReport)
      }
    }
  },
  computed: {
    ...mapGetters(['accidentIncidentReport'])
  }
}
</script>

<style scoped>

</style>
